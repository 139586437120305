<template>
  <main>
    <package-header title="Prepare Submission" subTitle="12764 Smith"></package-header>

    <div class="container-fluid">

      <div class="row">
        <div class="col">

          <div class="mb-3 border-bottom pb-3">
            <!-- Back to applications link -->
            <router-link to="/pages/submit-package">
              <svg class="icon icon-arrow_cta_back mr-sm-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta_back"></use></svg>
              <span class="d-none d-sm-inline-block">Return to Applications</span>
              <span class="d-sm-none">Back</span>
            </router-link>
          </div>
        </div>
      </div>

      <div class="row">

        <!-- Data entry form -->
        <div class="col-md-10 col-lg-8">
          <p class="lead">Add Applications to submit</p>

          <div class="sticky-table">
            <table class="table table-hover table-stacked table-select-row">
              <thead class="bg-medium">
                <tr>
                  <th scope="row">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="th">
                      <label class="custom-control-label" for="th">&nbsp;</label>
                    </div>
                  </th>
                  <th scope="col">Name</th>
                  <th scope="col">Type</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in tableItems" v-bind:class="{ 'text-muted': item.c === 'Drafted' }">
                  <td scope="row" style="width: 3rem">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" :id="index" :disabled="item.c == 'Drafted'">
                      <label class="custom-control-label" :for="index">&nbsp;</label>
                    </div>
                  </td>
                  <td data-header="Name">
                    <span class="td-content" v-bind:class="{ 'font-weight-bold': item.c != 'Drafted' }">{{ item.a }}</span>
                  </td>
                  <td data-header="Modified">
                    <span class="td-content">{{ item.b }}</span>
                  </td>
                  <td data-header="Status" v-html="item.c"></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="mt-3">
            <button class="btn btn-primary" :disabled="existingPackage">Add to a New Package</button>
            <button class="btn btn-primary" @click="existingPackage = !existingPackage">Add to an Existing Package</button>
          </div>

          <div class="bg-medium p-4 mt-4" v-if="existingPackage">
            <h3 class="mb-3">Select an EFS package to add applications to.</h3>
            <div class="form-group">
              <label for="">Search for a package by file reference or description</label>
              <div class="input-group col-md-8 px-0 mb-3">
                <input type="text" class="form-control" placeholder="Search...">
                <div class="input-group-append">
                  <button class="btn btn-primary btn-narrow" type="button">
                    <svg class="icon icon-search"><use xlink:href="/icons/symbol-defs.svg#icon-search"></use></svg>
                  </button>
                </div>
              </div>
            </div>

            <label>Filter By:</label>
            <div class="form-group">
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="claimantIndividual" name="claimant" class="custom-control-input">
                <label class="custom-control-label" for="claimantIndividual">My own</label>
              </div>
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="claimantCorporation" name="claimant" class="custom-control-input">
                <label class="custom-control-label" for="claimantCorporation">My Organization</label>
              </div>
            </div>

            <table class="table table-hover table-stacked table-select-row bg-white">
              <thead>
                <tr>
                  <th scope="row"></th>
                  <th scope="col">File Reference</th>
                  <th scope="col">Description</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in table2Items">
                  <td scope="row" style="width: 3rem">
                    <div class="custom-control custom-radio">
                      <input type="checkbox" class="custom-control-input" id="th">
                      <label class="custom-control-label" for="th">&nbsp;</label>
                    </div>
                  </td>
                  <td data-header="Name">
                    <span class="td-content"><strong>{{ item.a }}</strong></span>
                  </td>
                  <td data-header="Modified">
                    <span class="td-content">{{ item.b }}</span>
                  </td>
                  <td data-header="Status" v-html="item.c"></td>
                </tr>
              </tbody>
            </table>

            <button class="btn btn-primary">Send to EFS</button>
          </div>
        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right d-print-none scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <help-panel title="Searches &amp; STCs"></help-panel>
  </main>
</template>

<script>

var filters = {
  active: function (tableItems) {
    return tableItems.filter(function (tableItem) {
      return tableItem.selected
    })
  }
}

import PackageHeader from '../../components/PackageHeader.vue'
import PackageNavigation from '../../components/PackageNavigation.vue'
import HelpPanel from '../../components/HelpPanel.vue'

export default {

  components: {
    'package-header': PackageHeader,
    'package-navigation': PackageNavigation,
    'help-panel': HelpPanel
  },

  data () {
    return {
      existingPackage: false,
      tableItems: [
        {
          a:'A1 release',
          b:'Release',
          c:'Drafted',
        },
        {
          a:'A2 release',
          b:'Release',
          c:'<span class="td-content">E-signed <span class="badge badge-pill badge-success">&nbsp;</span></span>',
        },
        {
          a:'Mortgage Caso',
          b:'Create Mortgage',
          c:'Drafted',
        }
      ],
      table2Items: [
        {
          a:'12764 Smith',
          b:'Mortgage release',
          c:'Unsubmitted',
        },
        {
          a:'12907 Chan',
          b:'-',
          c:'Unsubmitted',
        },
        {
          a:'12801 Anderson',
          b:'Purchase of strata title',
          c:'Unsubmitted',
        },
        {
          a:'A1 Credit Union',
          b:'Mortgage release',
          c:'Unsubmitted',
        }
      ],
    }
  }
}
</script>

